import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
    console.log("BG");
  }

  onload() {
    console.log("onload");
    const hi = document.getElementById('headimg');
    console.log("Hi",this.element);
    hi.style.backgroundImage="url('/images/interiors/X00007.jpg')";
  }
}
